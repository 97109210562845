import './App.css';
import React, {  Suspense } from 'react';
import MapContainer from './Components/MapContainer';
import MapHeader from './Components/MapHeader';
import MapFooter from './Components/MapFooter';
import MobileLayout from './MobileLayout/MobileLayout'

function App() {
 
  return (
    <Suspense fallback={<div>Loading...</div>}>

      {window.innerWidth < 768 ? (
        <MobileLayout>
          <MapContainer />
        </MobileLayout>)
        : (<div>
          <MapHeader />
          <div style={{
            position: 'absolute',
            left: '0px',
            right: '0px',
            top: '144px',
            overflow: 'auto',
            zIndex: 1
          }}>
            <MapContainer />
          </div>
          <div style={{
            position: 'absolute',
            left: '0px',
            right: '0px',
            bottom: '0px',
            overflow: 'auto',
            zIndex: 2
          }}>
            <MapFooter />
          </div>
        </div>
        )}
    </Suspense>
  );
}

export default App;
