import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'antd';
// import Label from '../Label';
import Terms from './Terms';

export class MapFooter extends Component {

  state = {
    showWindow: false,
    windowTitle: '',
  }

  openWindow = (message) => {
    this.setState({ showWindow: true, windowTitle: message, });
  }

  handleCancel = () => {
    this.setState({ showWindow: false, windowTitle: '', });
  }

  render() {
    const { showWindow } = this.state;

    let ModalWindow = null;
    if (showWindow === true)
      ModalWindow = <Modal
        title={null}
        visible={showWindow}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        width={600}
        footer={null}
      >
        <Terms />
        <Row style={{ float: 'right', padding: '0px', marginTop: '8px', marginRight: '24px', marginBottom: '16px', position: 'absolute', bottom: '0px', right: '0px' }}>
          <Button size='large' style={{ float: 'right' }} onClick={() => this.handleCancel()}>Close</Button>
        </Row>
      </Modal>;

    return (
      <div id='mapFooter'>
        <Row style={{ height: '48px' }}  >
          <Col xl={10} lg={10} sm={10} xs={24} >
            <div id='copyright' ><a href="https://pos-abilities.org" target='_blank' rel="noreferrer" style={{color:'white'}}> {'© ' + (new Date().getFullYear()) + ' Langley Pos-Abilities Society'}</a></div>
          </Col>
          <Col xl={4} lg={4} sm={4} xs={24} >
            <Button id='termsButton' type='link' size={window.innerWidth >= 768 ? 'large' : 'small'} onClick={() => this.openWindow('Terms of Use')} style={{textAlign:'center'}}>Terms of Use</Button>
          </Col>
          <Col xl={10} lg={10} sm={10} xs={24} >
            <div id='charitableNum' >Charitable#: 805893468 RR0001</div>
          </Col>
        </Row>
        {ModalWindow}
      </div>
    )
  }


}
export default MapFooter