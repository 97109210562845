import React, { Component } from 'react';
import { Row, Col, Button, Checkbox, Steps, Radio, Input, Spin, Tooltip, Popover } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import axios from 'axios';
import Label from '../Label';
import { QuestionCircleFilled } from '@ant-design/icons';

const { Step } = Steps;

export class LocationReport extends Component {
  state = {
    mapSummary: [],
    reportType: '',
    steps: [],
    locationId: '',
    currentStep: 0,
    nextStep: 0,
    nextStepName: '',
    currentStepName: 'Start',
    currentStepId: '0',
    showExtraParking: false,
    report: {},
    reportDetails: [],
    placeTypes: [],
    questions: [],
    spinning: false,
    hasSeating: false,
    hasGym: false,
    hasPool: false,
    hasTheatre: false,
    hasLodging: false,
    hasGasBar: false,
    showRatingWarning: false
  }

  componentDidMount() {
    this.setState({ locationId: this.props.locationId, mapSummary: this.props.mapSummary, reportType: this.props.reportType, placeId: this.props.placeId, placeTypes: this.props.placeTypes, spinning: true, });
    this.loadReportSteps(this.props.reportType);
    let report = { type: this.props.reportType, locationId: this.props.locationId, locationName: this.props.locationName, latitude: this.props.mapSummary.lat, longitude: this.props.mapSummary.lng, placeId: this.props.mapSummary.placeId, mobility: true, contactFirstName: '', contactLastName: '', contactNumber: '', contactemail: '' };

    if (this.props.reportType === 'user') {
      report['vision'] = false;
      report['hearing'] = false;
      this.setState({ report: report, });
    }
    else {
      report['vision'] = true;
      report['hearing'] = true;
      this.setState({ report: report, });
    }
  }

  componentDidUpdate(prevProps, prevState) {
  }

  isReportStepIncluded = (step) => {
    const { hasSeating, hasGym, hasPool, hasTheatre, hasLodging, hasGasBar } = this.state;

    switch (step.stepName) {
      case 'Seating':
        return hasSeating

      case 'Gym':
        return hasGym

      case 'Pool':
        return hasPool

      case 'Theatre':
        return hasTheatre

      case 'Lodging':
        return hasLodging

      case 'Gas Bar':
        return hasGasBar

      default:
        return true
    }
  }

  defaultReportStep = (stepName) => {
    const { hasSeating, hasGym, hasPool, hasTheatre, hasLodging, hasGasBar } = this.state;

    switch (stepName) {
      case 'Seating':
        return hasSeating

      case 'Gym':
        return hasGym

      case 'Pool':
        return hasPool

      case 'Theatre':
        return hasTheatre

      case 'Lodging':
        return hasLodging

      case 'Gas Bar':
        return hasGasBar

      default:
        return false
    }
  }

  loadReportSteps = (reportType) => {
    let stepType = reportType;
    if (stepType === 'business')
      stepType = 'report';

    axios.get(`https://webmap.azurewebsites.net/api/report/steps/` + stepType)
      .then(res => {
        let steps = res.data["steps"];
        //remove excluded Steps
        steps = steps.filter(this.isReportStepIncluded);
        steps.splice(0, 0, { id: '0', seqNo: 0, stepName: 'Start' }).sort((a, b) => (a.seqNo < b.seqNo));
        steps.push({ id: steps.length, seqNo: steps.length, stepName: 'Submit' });
        this.setState({ steps: steps, spinning: false, });
      }).catch(err => {
        console.log(err)
      });
  }

  onStepChange = (step) => {
    const { steps, currentStep, reportType, report, reportDetails } = this.state;
    let stepName = steps[step].stepName;
    if (currentStep === 0) {
      this.loadQuestions(reportType, report.mobility, report.vision, report.hearing);
    }
    //currentStep is what determines if the step changes
    if (currentStep === 0 && (report.contactFirstName === '' || report.contactLastName === '' || report.contactEmail === '' || (report.mobility === false && report.vision === false && report.hearing === false))) {
      step = currentStep;
      stepName = steps[currentStep].stepName;
      //TODO show fields that are not selected
    }
    if (currentStep > 0 && currentStep < steps.length - 1 && reportType === 'user') {
      //Check that if they answered Yes to the first question, they have rated the question
      let currentFeature = reportDetails.filter(x => x.step === currentStep && x.type === 'feature');
      if (currentFeature.length > 0) {
        if (currentFeature[0].score === 1) {
          //check there is a rating
          let currentRating = reportDetails.filter(x => x.step === currentStep && x.type === 'rating');
          if (currentRating.length === 0 || (currentRating.length > 0 && currentRating[0].score === '')) {
            step = currentStep;
            stepName = steps[currentStep].stepName;
            //TODO warn user to select a rating
            this.setState({ showRatingWarning: true, });
          }
        }
      }
    }
    this.setState({ nextStep: step, nextStepName: stepName, currentStep: step, currentStepName: stepName, });
  };

  loadQuestions = (type, mobility, vision, hearing) => {
    let query = `https://webmap.azurewebsites.net/api/report/questions/` + type + `?mobility=` + mobility + `&vision=` + vision + `&hearing=` + hearing;
    let questions = [];
    let { reportDetails } = this.state;

    axios.get(query)
      .then(res => {
        questions = res.data["questions"];

        let newReportDetails = [];

        if (reportDetails.length > 0) {
          newReportDetails = questions.map(record => {

            let score = record.defaultValue;
            if (type === 'business' && this.defaultReportStep(record.stepName) && record.type === 'feature') {
              this.setQuestionScore(record.questionId, 1);
            }

            let currentIndex = reportDetails.findIndex(q => q.questionId.toString() === record.questionId.toString());
            if (currentIndex < 0) {
              return {
                type: record.type,
                questionId: record.questionId.toString(),
                questionText: record.question,
                stepName: record.stepName,
                seqNo: record.seqNo,
                score: score,
                moreButton: record.showMore,
                moreButtonValue: record.defaultValue
              }
            }
            return null;
          });
          reportDetails.push(newReportDetails);

          this.setState({ questions: questions, reportDetails: reportDetails, });

        }
        else {

          newReportDetails = questions.map(record => {
            let score = "";
            if (type === 'business' && this.defaultReportStep(record.stepName) && record.type === 'feature') {
              score = 1;
            }
            else {

              if (record.defaultValue === "" || record.defaultValue === null) {
                score = null;
              }

              else {
                score = Number(record.defaultValue);
              }

            }

            return {
              type: record.type,
              questionId: record.questionId.toString(),
              questionText: record.question,
              stepName: record.stepName,
              seqNo: record.seqNo,
              score: score,
              moreButton: record.showMore,
              moreButtonValue: score
            }
          });
          this.setState({ questions: questions, reportDetails: newReportDetails, });
        }


      }).catch(err => {
        console.log(err)
      });
  }

  changeFieldValue = (fieldName, fieldValue) => {
    let { report } = this.state;
    report[fieldName] = fieldValue;
    this.setState({ report: report, });
  }

  setQuestionScore = (questionId, score) => {
    const { reportDetails } = this.state;
    let details = reportDetails;
    let currentIndex = details.findIndex(question => question.questionId === questionId);
    if (currentIndex >= 0) {
      details[currentIndex].score = score;
      this.setState({ reportDetails: details, });
    }
  }

  setAnswer = (questionId, event, moreButton, moreButtonValue, questionType) => {
    const { reportDetails } = this.state;
    let details = reportDetails;
    let currentIndex = details.findIndex(question => question.questionId === questionId.toString());
    let score = null;

    if (event !== null) {
      score = event.target.value;

      if (currentIndex >= 0) {
        moreButton = details[currentIndex].moreButton;
        moreButtonValue = details[currentIndex].moreButtonValue;
      }
    }
    else {
      if (currentIndex >= 0) {
        score = details[currentIndex].score;
      }
    }

    if (currentIndex >= 0) {
      details[currentIndex].score = score;
      details[currentIndex].moreButton = moreButton;
      details[currentIndex].moreButtonValue = moreButtonValue;
      details[currentIndex].type = questionType;

    }
    else {
      details.push({ type: questionType, questionId: questionId, score: score, moreButton: moreButton, moreButtonValue: moreButtonValue });
    }

    this.setState({ reportDetails: details, });
  }

  getPlaceTypeName = (placeType) => {
    const { placeTypes } = this.state;
    let searchTypes = placeTypes;
    searchTypes = searchTypes.filter(x => x.placeType === placeType);
    if (searchTypes.length > 0) {
      return (searchTypes[0].placeTypeName);
    }
    else { return null; }
  }

  submitReport = () => {
    const { report, reportDetails, mapSummary } = this.state;

    let locationReportData = [];

    locationReportData = reportDetails.filter(x => x.score !== null).map(record => {
      return {
        type: record.type,
        questionId: record.questionId.toString(),
        questionText: record.questionText.toString(),
        stepName: record.stepName,
        seqNo: record.seqNo,
        score: record.score.toString()
      }
    });



    let locationReport = { ...report, address: { streetAddress: mapSummary.streetAddress, city: mapSummary.city, region: mapSummary.region, postalCode: mapSummary.postalCode }, locationReportData: locationReportData };
    let query = `https://webmap.azurewebsites.net/api/report/submit`;
    this.setState({ spinning: true, });

    axios.post(query, locationReport)
      .then(res => {
        this.setState({ spinning: false, });
        let avgScore = 0.00;
        let score = 0.0;
        let ratings = 0;
        //filter by rating records
        locationReportData = locationReportData.filter(x => x.type === 'rating');
        //get sum of all rating scores
        avgScore = locationReportData.map(d => d.score).reduce((a, b) => parseFloat(a) + parseFloat(b));
        ratings = locationReportData.length;
        //calculate score
        score = avgScore/ratings;
        //show percentage as a whole number
        score = score * 100;

        this.props.closeReport(true, report.locationId === '', score);
      }).catch(err => {
        this.setState({ spinning: false, });
        console.log(err)
      });
  }

  questionValue = (questionId, scoreOnly, defaultValue) => {
    const { reportDetails } = this.state;
    let details = reportDetails;

    let result = ""
    if (defaultValue)
      result = Number(defaultValue);
    else
      result = null;

    let currentIndex = details.findIndex(question => question.questionId === questionId.toString());

    if (currentIndex >= 0) {
      if (scoreOnly === false && details[currentIndex].moreButton === true) {
        result = details[currentIndex].moreButtonValue;
      }
      else {
        result = details[currentIndex].score;
      }
    }

    return result;
  }

  render() {
    const { showRatingWarning, spinning, steps, currentStep, currentStepName, mapSummary, report, reportType, questions } = this.state;

    const getQuestions = (stepName) => {
      let stepQuestions = questions.filter(x => x.stepName === stepName).sort((a, b) => (a.seqNo < b.seqNo) ? -1 : 0);
      return stepQuestions
    }

    const getAnswers = (answers) => {
      let stepAnswer = eval(answers);
      return stepAnswer
    }

    let howTo = <Row style={{ paddingBottom: '4px', marginBottom: '8px' }}>
      <Label labelType='H5' caption={reportType === 'user' ? 'How to Report On This Location' : 'How to Report On Your Location'} style={{ marginBottom: '0px', borderBottom: 'dotted darkgrey thin' }} />
      {reportType === 'user' ?
        <React.Fragment>
          <Label caption="The report is separated into sections. The first question in each section will determine if you will be asked more questions.  " block />
          <Label caption="If you click 'Yes', you will be asked to rate accessibility.  If you want to answer more questions in this section, you can click the 'I would like to answer more questions' button to provide more feedback.  " block />
          <Label caption="Select an answer that reflects your judgement. If the question does not apply to you, leave the answer as 'N/A'. " block />
          <Label caption="To move to the next section, click the 'Next' button on the bottom right of each section." block />
        </React.Fragment>
        :
        <React.Fragment>
          <Label caption="The report is separated into sections. The first question in each section will determine if you will be asked more questions. " block />
          <Label caption="If you click 'Yes', you will be asked a series of questions which by default will be set to 'No'.  If your location has the accessibility option, make sure to select 'Yes'." block />
          <Label caption="To move to the next section, click the 'Next' button on the bottom right of each section." block />
        </React.Fragment>
      }
    </Row>;

    let noRatingWarning = <div style={{ height: '80px' }}>
      <Label caption='You must select a rating in order to continue.' block labelType='Subtitle1' style={{ marginBottom: '16px' }} />
      <Button type="primary" style={{ float: 'right', marginBottom: '16px' }} onClick={() => this.setState({ showRatingWarning: false, })}>Close</Button>
    </div>;

    let noRating = <Popover
      content={noRatingWarning}
      title="We need your feedback"
      trigger="click"
      open={showRatingWarning}
      placement='rightTop'
    // onVisibleChange={this.handleVisibleChange}
    >
    </Popover>

    let userReportStep = null;
    if (window.innerWidth >= 768) {
      if (currentStep === 0)
        userReportStep =
          <div style={{ minHeight: reportType === 'user' ? '400px' : '320px' }}>
            <Row style={{ paddingBottom: '4px', marginBottom: '8px' }}>
              <Col span={24} style={{ borderBottom: 'dotted darkgrey thin', marginBottom: '4px' }}>
                <Label labelType='H5' caption='Your Contact Information' style={{ marginBottom: '0px', borderBottom: 'dotted darkgrey thin' }} />
              </Col>
            </Row>
            <Row style={{ paddingBottom: '4px', marginBottom: '8px' }}>
              <Col span={8}>
                <Label caption='First Name *' />
                <Input
                  name='firstName'
                  size="large"
                  autoFocus={true}
                  allowClear={true}
                  maxLength={30}
                  value={report.contactFirstName}
                  onChange={e => this.changeFieldValue('contactFirstName', e.target.value)}
                />
              </Col>
              <Col span={14} offset={2}>
                <Label caption='Last Name *' />
                <Input
                  name='lastName'
                  size="large"
                  allowClear={true}
                  maxLength={30}
                  value={report.contactLastName}
                  onChange={e => this.changeFieldValue('contactLastName', e.target.value)}
                />
              </Col>
            </Row>
            <Row style={{ paddingBottom: '4px', marginBottom: '8px' }}>
              <Col span={8} >
                <Label caption='Contact Number' />
                <MaskedInput
                  name='contactNumber'
                  autoComplete={'off'}
                  size="large"
                  mask="(111) 111-1111"
                  allowClear={true}
                  maxLength={31}
                  value={report.contactNumber}
                  onChange={e => this.changeFieldValue('contactNumber', e.target.value)}
                />
              </Col>
              <Col span={14} offset={2}>
                <Label caption='Email Address *' />
                <Input
                  name='emailAddress'
                  size="large"
                  allowClear={true}
                  maxLength={255}
                  value={report.contactEmail}
                  onChange={e => this.changeFieldValue('contactEmail', e.target.value)}
                />
              </Col>
            </Row>
            <Row style={{ paddingBottom: '4px', marginBottom: '8px' }}>
              <Label caption='* Required fields' labelType='Body1'></Label>
            </Row>
            <Row style={{ paddingBottom: '4px', marginBottom: '8px' }}>
              <Col span={24} style={{ marginBottom: '4px' }}>
                <Label caption='By providing the above personal information you are giving the Society “deemed consent” under the Personal Information Privacy Act (PIPA). The personal information we gather (name, phone number and email) is for the Society’s internal use only and will not be disclosed or shared with any outside agency.' labelType='Subtitle'></Label>
              </Col>
            </Row>

            {reportType === 'user' ?
              <Row style={{ paddingBottom: '4px', marginBottom: '16px', marginTop: '4px' }}>
                <Label caption='Select which disabilities apply to you or your companion:' labelType='H6' block ></Label>
                <Checkbox style={{ fontSize: '18px', width: '100%', padding: '8px', marginLeft: '16px' }} onClick={e => this.changeFieldValue('mobility', e.target.checked)} checked={report.mobility} >Mobility</Checkbox>
                <Checkbox style={{ fontSize: '18px', width: '100%', padding: '8px', marginLeft: '16px' }} onClick={e => this.changeFieldValue('vision', e.target.checked)} checked={report.vision}>Vision</Checkbox>
                <Checkbox style={{ fontSize: '18px', width: '100%', padding: '8px', marginLeft: '16px' }} onClick={e => this.changeFieldValue('hearing', e.target.checked)} checked={report.hearing}>Hearing</Checkbox>
              </Row>
              : null}

            {reportType === 'business' ?
              <Row style={{ paddingBottom: '24px', marginBottom: '36px', marginTop: '4px' }}>
                <Label caption='Do you have any of the following at your location?' labelType='H6' block ></Label>
                <Checkbox style={{ fontSize: '18px', width: '30%', padding: '8px', marginLeft: '16px' }} onClick={e => this.setState({ 'hasSeating': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasSeating} >Seating</Checkbox>
                <Checkbox style={{ fontSize: '18px', width: '30%', padding: '8px', marginLeft: '16px' }} onClick={e => this.setState({ 'hasGym': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasGym}>Gym</Checkbox>
                <Checkbox style={{ fontSize: '18px', width: '30%', padding: '8px', marginLeft: '16px' }} onClick={e => this.setState({ 'hasPool': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasPool}>Pool</Checkbox>
                <Checkbox style={{ fontSize: '18px', width: '30%', padding: '8px', marginLeft: '16px' }} onClick={e => this.setState({ 'hasTheatre': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasTheatre}>Theatre</Checkbox>
                <Checkbox style={{ fontSize: '18px', width: '30%', padding: '8px', marginLeft: '16px' }} onClick={e => this.setState({ 'hasLodging': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasLodging}>Lodging</Checkbox>
                <Checkbox style={{ fontSize: '18px', width: '30%', padding: '8px', marginLeft: '16px' }} onClick={e => this.setState({ 'hasGasBar': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasGasBar}>Gas Bar</Checkbox>
              </Row>
              : null}

            <Row style={{ position: 'absolute', bottom: 0, right: 0, width: '100%' }}>
              <Col span={16} offset={8}>
                {report.contactFirstName === '' || report.contactLastName === '' || report.contactEmail === '' || (report.mobility === false && report.vision === false && report.hearing === false) ?
                  <Button size='large' style={{ float: 'right', marginRight: '16px' }} onClick={() => this.onStepChange(1)}>Start My Review</Button> :
                  <Button size='large' type='primary' style={{ float: 'right', marginRight: '16px' }} onClick={() => this.onStepChange(1)}>Start My Review</Button>
                }     </Col>
            </Row>
          </div>;
      else if (currentStep === steps.length - 1) {
        userReportStep =
          <div style={{ minHeight: '400px', marginTop: '8px' }}>
            <Label caption='Thank you for taking the time to submit your accessibility report!' labelType='H4' />
            <Row style={{ position: 'absolute', bottom: 0, right: 0, width: '100%' }}>
              <Col span={16} offset={8}>
                <Button size='large' style={{ float: 'right', marginRight: '0px' }} onClick={() => this.submitReport()}>Start My Review</Button>
              </Col>
            </Row>
          </div>
      }
      else {
        userReportStep = <div style={{ minHeight: '400px', marginTop: '8px' }}>
          {getQuestions(currentStepName).map(question => {
            let answers = getAnswers(question.answers);
            return (<Row style={{ paddingBottom: '4px', marginBottom: '8px', marginTop: '0px', display: (question.required === true && question.dependentQuestion === null) || this.questionValue(question.dependentQuestion, false, question.defaultValue) === 1 ? 'flex' : 'none' }} key={question.questionId}>
              <Col span={answers.length > 3 ? 24 : 15}>
                <Label caption={question.question} key={question.questionId + 'Q'} labelType={question.dependentQuestion ? 'Body1' : 'Subtitle1'} style={{ marginLeft: '0px', marginBottom: answers.length > 3 ? '8px' : '0px' }} />
                <Label caption={question.questionDescription} key={question.questionId + 'D'} labelType='Body2' style={{ fontWeight: 900, marginBottom: answers.length > 3 ? '8px' : '0px' }} />
              </Col>
              <Col span={answers.length > 3 ? 24 : 8} offset={answers.length > 3 ? 0 : 1} >
                <Radio.Group buttonStyle="solid" style={{ marginTop: '0px', fontWeight: 400, float: 'right' }} size='large' value={this.questionValue(question.questionId, true, question.defaultValue)} onChange={(e) => this.setAnswer(question.questionId, e, question.showMore, 0, question.type)}>
                  {answers.map(option => {
                    return (<Radio.Button value={option.value} key={question.questionId + 'V' + option.value}> <strong>{option.caption}</strong> </Radio.Button >)
                  })}
                </Radio.Group >
                {question.showMore === true ?
                  <React.Fragment>
                    <Button size='large' type='primary' onClick={() => this.setAnswer(question.questionId, null, true, 1, question.type)} style={{ float: 'right', margin: '16px 0px', fontWeight: '600' }}>I would like to answer more questions...</Button>
                    {noRating}
                  </React.Fragment> : null}
              </Col>
            </Row>
            )
          })}
          <Row style={{ paddingBottom: '24px' }}>
          </Row>
          <Row style={{ position: 'absolute', bottom: 0, right: 0, width: '100%' }}>
            <Col span={8}>

              {/* {currentStep > 0 ? <Button size='large' style={{ marginLeft: '40px' }} onClick={() => this.onStepChange(currentStep - 1)}>Back</Button> : null} */}
            </Col>
            <Col span={16}>
              {currentStep < steps.length - 1 ? <Button size='large' style={{ float: 'right', marginRight: '0px' }} onClick={() => this.onStepChange(currentStep + 1)}>Next</Button> : null}
            </Col>
          </Row>
        </div>;
      }
    }
    else {
      if (currentStep === 0)
        userReportStep =
          <div style={{ minHeight: reportType === 'user' ? '400px' : '320px' }}>
            <Row style={{ paddingBottom: '4px', marginBottom: '8px' }}>
              <Col span={24} style={{ borderBottom: 'dotted darkgrey thin', marginBottom: '4px' }}>
                <Label labelType='H5' caption='Your Contact Information' style={{ marginBottom: '0px', borderBottom: 'dotted darkgrey thin' }} />
              </Col>
            </Row>
            <Row style={{ paddingBottom: '4px', marginBottom: '8px' }}>
              <Col span={24}>
                <Label caption='First Name *' />
                <Input
                  name='firstName'
                  size="large"
                  autoFocus={true}
                  allowClear={true}
                  maxLength={30}
                  value={report.contactFirstName}
                  onChange={e => this.changeFieldValue('contactFirstName', e.target.value)}
                />
              </Col>
              <Col span={24} >
                <Label caption='Last Name *' />
                <Input
                  name='lastName'
                  size="large"
                  allowClear={true}
                  maxLength={30}
                  value={report.contactLastName}
                  onChange={e => this.changeFieldValue('contactLastName', e.target.value)}
                />
              </Col>

              <Col span={24} >
                <Label caption='Email Address *' />
                <Input
                  name='emailAddress'
                  size="large"
                  allowClear={true}
                  maxLength={255}
                  value={report.contactEmail}
                  onChange={e => this.changeFieldValue('contactEmail', e.target.value)}
                />
              </Col>
              <Col span={16} >
                <Label caption='Contact Number' />
                <MaskedInput
                  name='contactNumber'
                  autoComplete={'off'}
                  size="large"
                  mask="(111) 111-1111"
                  allowClear={true}
                  maxLength={31}
                  value={report.contactNumber}
                  onChange={e => this.changeFieldValue('contactNumber', e.target.value)}
                />
              </Col>
            </Row>
            <Row style={{ paddingBottom: '4px', marginBottom: '8px' }}>
              <Label caption='* Required fields' labelType='Body1'></Label>
            </Row>
            <Row style={{ paddingBottom: '4px', marginBottom: '8px' }}>
              <Col span={24} style={{ marginBottom: '4px' }}>
                <Label caption='By providing the above personal information you are giving the Society “deemed consent” under the Personal Information Privacy Act (PIPA). The personal information we gather (name, phone number and email) is for the Society’s internal use only and will not be disclosed or shared with any outside agency.' labelType='Subtitle'></Label>
              </Col>
            </Row>

            {reportType === 'user' ?
              <Row style={{ paddingBottom: '4px', marginBottom: '48px', marginTop: '4px' }}>
                <Label caption='Select which disabilities apply to you or your companion:' labelType='H6' block ></Label>
                <Checkbox style={{ fontSize: '18px', width: '33%', padding: '8px', marginLeft: '0px' }} onClick={e => this.changeFieldValue('mobility', e.target.checked)} checked={report.mobility} >Mobility</Checkbox>
                <Checkbox style={{ fontSize: '18px', width: '33%', padding: '8px', marginLeft: '0px' }} onClick={e => this.changeFieldValue('vision', e.target.checked)} checked={report.vision}>Vision</Checkbox>
                <Checkbox style={{ fontSize: '18px', width: '33%', padding: '8px', marginLeft: '0px' }} onClick={e => this.changeFieldValue('hearing', e.target.checked)} checked={report.hearing}>Hearing</Checkbox>
              </Row>
              : null}

            {reportType === 'business' ?
              <Row style={{ paddingBottom: '24px', marginBottom: '36px', marginTop: '4px' }}>
                <Label caption='Do you have any of the following at your location?' labelType='H6' block ></Label>
                <Checkbox style={{ fontSize: '16px', width: '33%', padding: '8px', marginLeft: '0px' }} onClick={e => this.setState({ 'hasSeating': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasSeating} >Seating</Checkbox>
                <Checkbox style={{ fontSize: '16px', width: '33%', padding: '8px', marginLeft: '0px' }} onClick={e => this.setState({ 'hasGym': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasGym}>Gym</Checkbox>
                <Checkbox style={{ fontSize: '16px', width: '33%', padding: '8px', marginLeft: '0px' }} onClick={e => this.setState({ 'hasPool': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasPool}>Pool</Checkbox>
                <Checkbox style={{ fontSize: '16px', width: '33%', padding: '8px', marginLeft: '0px' }} onClick={e => this.setState({ 'hasTheatre': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasTheatre}>Theatre</Checkbox>
                <Checkbox style={{ fontSize: '16px', width: '33%', padding: '8px', marginLeft: '0px' }} onClick={e => this.setState({ 'hasLodging': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasLodging}>Lodging</Checkbox>
                <Checkbox style={{ fontSize: '16px', width: '33%', padding: '8px', marginLeft: '0px' }} onClick={e => this.setState({ 'hasGasBar': e.target.checked, }, this.loadReportSteps(reportType))} checked={this.state.hasGasBar}>Gas Bar</Checkbox>
              </Row>
              : null}

            <Row style={{ position: 'absolute', bottom: 0, right: 0, width: '100%' }}>
              <Col span={16} offset={8}>
                {report.contactFirstName === '' || report.contactLastName === '' || report.contactEmail === '' || (report.mobility === false && report.vision === false && report.hearing === false) ?
                  <Button size='large' style={{ float: 'right', marginRight: '0px' }} onClick={() => this.onStepChange(1)}>Start My Review</Button> :
                  <Button size='large' type='primary' style={{ float: 'right', marginRight: '0px' }} onClick={() => this.onStepChange(1)}>Start My Review</Button>
                }     </Col>
            </Row>
          </div>;
      else if (currentStep === steps.length - 1) {
        userReportStep =
          <div style={{ minHeight: '400px', marginTop: '8px' }}>
            <Label caption='Thank you for taking the time to submit your accessibility report!' labelType='H5' />
            <Row style={{ position: 'absolute', bottom: 0, right: 0, width: '100%' }}>
              <Col span={8}>
                {currentStep > 0 ? <Button size='large' style={{ marginLeft: '0px' }} onClick={() => this.onStepChange(currentStep - 1)}>Back</Button> : null}
              </Col>
              <Col span={16} >
                <Button size='large' type='primary' style={{ float: 'right', marginRight: '0px' }} onClick={() => this.submitReport()}>Start My Review</Button>
              </Col>
            </Row>
          </div>
      }
      else {
        userReportStep = <div style={{ minHeight: '400px', marginTop: '8px' }}>
          {getQuestions(currentStepName).map(question => {
            let answers = getAnswers(question.answers);
            return (<Row style={{ paddingBottom: '4px', marginBottom: '8px', marginTop: '0px', display: (question.required === true && question.dependentQuestion === null) || this.questionValue(question.dependentQuestion, false, question.defaultValue) === 1 ? 'flex' : 'none' }} key={question.questionId}>
              <Col span={24}>
                <Label caption={question.question} key={question.questionId + 'Q'} labelType={question.dependentQuestion ? 'Body1' : 'Subtitle1'} style={{ marginLeft: question.dependentQuestion ? '0px' : '0px' }} />
                <Label caption={question.questionDescription} key={question.questionId + 'D'} labelType='Body2' style={{ fontWeight: 900 }} />
              </Col>
              <Col span={24} style={{ marginTop: '8px' }}>
                <Radio.Group buttonStyle="solid" style={{ marginTop: '0px', fontWeight: 400, width: '100%', float: 'right' }} size='large' value={this.questionValue(question.questionId, true, question.defaultValue)} onChange={(e) => this.setAnswer(question.questionId, e, question.showMore, 0, question.type)}>
                  {answers.map(option => {
                    return (<Radio.Button value={option.value} key={question.questionId + 'V' + option.value} style={{ display: answers.length > 3 ? 'block' : 'inline-block', float: answers.length > 3 ? 'none' : 'right', width: answers.length > 3 ? '100%' : 'auto', textAlign: 'center' }}> <strong>{option.caption}</strong> </Radio.Button >)
                  })}
                </Radio.Group >
                {question.showMore === true ?
                  <React.Fragment>
                    <Button size='large' type='primary' onClick={() => this.setAnswer(question.questionId, null, true, 1, question.type)} style={{ float: 'right', margin: '16px 0px', fontWeight: '600' }}>I would like to answer more questions...</Button>
                    {noRating}
                  </React.Fragment> : null}
              </Col>
            </Row>
            )
          })}
          <Row style={{ paddingBottom: '48px' }}>
          </Row>
          <Row style={{ position: 'absolute', bottom: 0, right: 0, width: '100%' }}>
            <Col span={8}>

              {currentStep > 0 ? <Button size='large' style={{ marginLeft: '0px' }} onClick={() => this.onStepChange(currentStep - 1)}>Back</Button> : null}
            </Col>
            <Col span={16}>
              {currentStep < steps.length - 1 ? <Button size='large' style={{ float: 'right', marginRight: '0px' }} onClick={() => this.onStepChange(currentStep + 1)}>Next</Button> : null}
            </Col>
          </Row>
        </div>;
      }
    }

    if (window.innerWidth >= 768)
      return (
        <Spin spinning={spinning}>
          <Row style={{ padding: '0px 0px', marginBottom: '24px' }}>
            <Label caption='Accessibility Report' labelType='H4' />
          </Row>
          <Row style={{ padding: '4px 16px', margin: '0px -24px 16px -24px', border: 'thin solid grey', color: 'black', background: '#F4FCFF' }}>
            <Col span={4}>
              {mapSummary.category ? mapSummary.category.map(placeType => {
                if (this.getPlaceTypeName(placeType))
                  return (<Label labelType='Tag' style={{ color: 'white', backgroundColor: 'black', padding: '4px 8px', margin: '8px 8px 8px 0px', borderRadius: '4px' }} key={placeType} caption={this.getPlaceTypeName(placeType)} />)
                else
                  return null
              }) : null}
            </Col>
            <Col span={11} >
              <Label caption={mapSummary.locationName} labelType='H5' block />
            </Col>
            <Col span={8} offset={1}>
              <Label caption={mapSummary.streetAddress} labelType='Body1' block />
              <Label caption={mapSummary.city + ',' + mapSummary.region + ' ' + mapSummary.postalCode} labelType='Body1' block />
              <Label caption={mapSummary.phoneNumber} labelType='Body1' />
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <Steps current={currentStep} direction="vertical" progressDot onChange={this.onStepChange} style={{ marginLeft: '0px' }} >
                {steps.map(step => {
                  return (
                    <Step title={step.stepName} key={step.seqNo} />)
                })}
              </Steps>
              <Tooltip title={howTo} style={{ width: '500px' }}> <QuestionCircleFilled style={{ fontSize: '24px' }} /></Tooltip>
            </Col>
            <Col span={20} >
              {userReportStep}
            </Col>
          </Row>

        </Spin>
      )
    else
      return (
        <Spin spinning={spinning}>
          <Row style={{ padding: '0px 0px', marginBottom: '24px' }}>
            <Label caption='Accessibility Report' labelType='H6' />
          </Row>
          <Row style={{ padding: '4px 16px', margin: '0px -24px 16px -24px', border: 'thin dotted grey', color: 'black', background: 'white' }}>

            <Col span={24} >
              <Label caption={mapSummary.locationName} labelType='H5' block />
            </Col>
            <Col span={8} >
              {mapSummary.category ? mapSummary.category.map(placeType => {
                if (this.getPlaceTypeName(placeType))
                  return (<Label labelType='Tag' style={{ color: 'white', backgroundColor: 'black', padding: '4px 8px', margin: '8px 8px 8px 0px', borderRadius: '4px' }} key={placeType} caption={this.getPlaceTypeName(placeType)} />)
                else
                  return null
              }) : null}
            </Col>
            <Col span={16} >
              <Label caption={mapSummary.streetAddress} labelType='Body1' block />
              <Label caption={mapSummary.city + ',' + mapSummary.region + ' ' + mapSummary.postalCode} labelType='Body1' block />
              <a href={"tel:" + mapSummary.phoneNumber}><Label caption={mapSummary.phoneNumber} labelType='Body1' block /></a>
            </Col>
          </Row>
          <Row>
            <Col span={24} >
              {userReportStep}
            </Col>
          </Row>

        </Spin>
      )
  }

}
export default LocationReport