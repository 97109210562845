import React from 'react';
import WebMap from './WebMap';
import { GoogleApiWrapper } from 'google-maps-react';
const GOOGLE_API_KEY = 'AIzaSyAlmOLYP6Ymi0yF3670LIbrDF7R - S3B7m4';

export class MapContainer extends React.Component {

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  handleResize = () =>
  {
    // Set state so re-render happens
    this.setState({});
  }

  render() {
    return (
      <WebMap google={this.props.google} />
    )
  }
}

export default GoogleApiWrapper({
  apiKey: (GOOGLE_API_KEY), libraries: ['places']
})(MapContainer)