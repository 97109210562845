import React, { Component } from 'react';
import { Row, Col, Tooltip, Spin } from 'antd';
import Label from '../Label';
import axios from 'axios';

export class MapMobilityDetails extends Component {
  state = {
    locationId: '',
    disability: '',
    steps: [],
    pros: [],
    cons: [],
    reportType: 'business',
    spinning: false,
  }

  componentDidMount() {
    this.loadReportSteps(this.state.reportType);
    this.setState({ locationId: this.props.locationId, disability: this.props.disability, }, () => this.loadDetails());
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps || this.state.locationId !== prevState.locationId || this.state.disability !== prevState.disability) {
      this.loadDetails();
    }
  }

  loadReportSteps = (reportType) => {
    let stepType = reportType;
    if (stepType === 'business')
      stepType = 'report';

    axios.get(`https://webmap.azurewebsites.net/api/report/steps/` + stepType)
      .then(res => {
        let steps = res.data["steps"];
        steps.splice(0, 0, { id: '0', seqNo: 0, stepName: 'Start' }).sort((a, b) => (a.seqNo < b.seqNo));
        this.setState({ steps: steps, });
      }).catch(err => {
        console.log(err)
      });
  }

  loadDetails = () => {
    let { disability, locationId } = this.state;
    this.setState({ spinning: true, });

    axios.get(`https://webmap.azurewebsites.net/api/map/location/procons/` + locationId + `/` + disability.toLowerCase())
      .then(res => {
        let mapDetails = res.data["locationReportDetailModels"];
        let pros = mapDetails.filter(x => x.type === 'pro').sort((a, b) => (a.questionOrder < b.questionOrder));
        let cons = mapDetails.filter(x => x.type === 'con').sort((a, b) => (a.questionOrder < b.questionOrder));
        this.setState({ pros: pros, cons: cons, spinning: false, });
      }).catch(err => {
        console.log(err)
      });

  };

  render() {
    const { steps, pros, cons, spinning } = this.state;

    if (window.innerWidth >= 768)
      return (

        <Spin spinning={spinning} tip='Loading Pros and Cons...'>
          {pros.length > 0 | cons.length>0?
          <div style={{ marginBottom: '0px', overflowY: 'scroll', maxHeight: '500px' }}>
            {steps.map(step => {
              if (cons.filter(x => x.stepName === step.stepName).length + pros.filter(x => x.stepName === step.stepName).length > 0)
                return (
                  <React.Fragment key={step.stepName}>
                    <Row style={{ paddingTop: '16px', paddingBottom: '8px' }}>
                      <Label caption={step.stepName.toUpperCase()} labelType='H6' />
                    </Row>
                    <Row>
                      <Col span={12} style={{ textAlign: 'center', border: 'solid black thin', background: 'lightgrey', padding: '4px' }}>
                        <Label caption='Good Access' labelType='Subtitle1' />
                      </Col>
                      <Col span={12} style={{ textAlign: 'center', border: 'solid black thin', background: 'lightgrey', padding: '4px' }}>
                        <Label caption='Poor Access' labelType='Subtitle1' />
                      </Col>
                    </Row>
                    <Row style={{ paddingBottom: '0px' }}>
                      <Col span={12} style={{ border: 'dotted grey thin', padding: '8px 16px 8px 16px' }} >
                        {pros.filter(x => x.stepName === step.stepName).map(pro => {
                          return (
                            <Tooltip title={pro.altText}><Label caption={pro.name} labelType='Body1' block /></Tooltip>
                          )
                        })}
                      </Col>
                      <Col span={12} style={{ border: 'dotted grey thin', padding: '8px 16px 8px 16px' }}>
                        {cons.filter(x => x.stepName === step.stepName).map(con => {
                          return (
                            <Tooltip title={con.altText}><Label caption={con.name} labelType='Body1' block /></Tooltip>
                          )
                        })}
                      </Col>
                    </Row>
                  </React.Fragment>
                )
              else
                return null;
            })
            }
          </div>:
            <div style={{ marginBottom: '0px', minHeight: '100px' }}>
                <Label caption='Unfortunately there are no accessibility details available for this location yet.' labelType='H6'/>
            </div>}
        </Spin>
      )
    else
      return (

        <Spin spinning={spinning} tip='Loading Pros and Cons...'>
          {pros.length > 0 | cons.length > 0 ?
          <div style={{ marginBottom: '16px', overflowY: 'scroll', maxHeight: '500px' }}>
            {steps.map(step => {
              if (cons.filter(x => x.stepName === step.stepName).length + pros.filter(x => x.stepName === step.stepName).length > 0)
                return (
                  <React.Fragment key={step.stepName } >
                    <Row style={{ paddingTop: '0px', paddingBottom: '8px' }}>
                      <Label caption={step.stepName.toUpperCase()} labelType='H6' />
                    </Row>

                    <Row style={{ paddingBottom: '16px' }}>
                      {pros.filter(x => x.stepName === step.stepName).length > 0 ?
                        <React.Fragment key = {step.stepName + 'goodAccess'}>
                          <Col span={24} style={{ textAlign: 'left', border: 'none', background: 'lightgrey', padding: '4px 8px' }} key={step.stepName + 'goodAccessList'}>
                            <Label caption=' Good Access' labelType='Subtitle1' />
                          </Col>
                          <Col span={24} style={{ border: 'dotted grey thin', padding: '8px 16px 8px 16px' }} >
                            {pros.filter(x => x.stepName === step.stepName).map(pro => {
                              return (
                                <Tooltip title={pro.altText} key={pro.altText}><Label caption={pro.name} labelType='Body1' block /></Tooltip>
                              )
                            })}
                          </Col>
                        </React.Fragment> : null}
                      {cons.filter(x => x.stepName === step.stepName).length > 0 ?
                        <React.Fragment key={step.stepName + 'poorAccess'}>
                          <Col span={24} style={{ textAlign: 'left', border: 'none', background: 'lightgrey', padding: '4px 8px' }} key={step.stepName + 'poorAccessList'}>
                            <Label caption=' Poor Access' labelType='Subtitle1' />
                          </Col>
                          <Col span={24} style={{ border: 'dotted grey thin', padding: '8px 16px 8px 16px' }}>
                            {cons.filter(x => x.stepName === step.stepName).map(con => {
                              return (
                                <Tooltip title={con.altText} key={con.altText}><Label caption={con.name} labelType='Body1' block /></Tooltip>
                              )
                            })}
                          </Col>
                        </React.Fragment> : null}
                    </Row>
                  </React.Fragment>
                )
              else
                return null;
            })
            }
            </div> :
            <div style={{ marginBottom: '0px', minHeight: '100px' }}>
              <Label caption='Unfortunately there are no accessibility details available for this location yet.' labelType='H6' />
            </div>}

        </Spin>

      )

  }

}
export default MapMobilityDetails