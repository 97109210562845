import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import logosmall from '../Assets/am-logo.png';
import AboutUsIcon from '@material-ui/icons/Help';
// import logo from '../Assets/Logo-header.png';
import markerIconBlack from '../Assets/map-marker.png';
import Label from '../Label';
import AboutUs from '../Components/AboutUs';


export class MapHeader extends Component {
  state = {
    showWindow: false,
    windowTitle: '',
  }

  openWindow = (message) => {
    this.setState({ showWindow: true, windowTitle: message, });
  }

  handleCancel = () => {
    this.setState({ showWindow: false, windowTitle: '', });
  }
  
  handleShowAboutUs = () => {
    this.setState({ showAboutUs: true, });
  };

  handleCancelAboutUs = () => {
    this.setState({ showAboutUs: false, });
  };

  render() {
    const { showWindow, windowTitle, showAboutUs } = this.state;

    let ModalWindow = null;
    if (showWindow === true)
      ModalWindow = <Modal
        title={windowTitle}
        open={showWindow}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        width={600}
        footer={null}
      >
        <Row style={{ paddingBottom: '48px' }}>
          Questions about location
        </Row>

        <Row style={{ float: 'right', padding: '0px', marginTop: '8px', marginRight: '24px', marginBottom: '16px', position: 'absolute', bottom: '0px', right: '0px' }}>
          <Button size='large' type='primary' style={{ marginRight: '16px', float: 'right' }}>Save My Report</Button>
          <Button size='large' style={{ float: 'right' }} onClick={() => this.handleCancel()}>Cancel</Button>
        </Row>
      </Modal>;

    let AboutUsModal = null;
    if (showAboutUs === true)
      AboutUsModal = <Modal
        title={null}
        visible={showAboutUs}
        onCancel={this.handleCancelAboutUs}
        style={{ top: '56px', left: '0px', width: window.innerWidth, height: window.innerHeight - 56 }}
        footer={null}
      >
        <AboutUs />
        <a href="https://pos-abilities.org" target='_blank' rel="noreferrer" ><h4 style={{ color: '#0c8db4' }}>Go to our website</h4></a>
        <Row style={{ float: 'right', padding: '0px', marginTop: '8px', marginRight: '24px', marginBottom: '16px', position: 'absolute', bottom: '0px', right: '0px' }}>
          <Button size='large' style={{ float: 'right' }} onClick={() => this.handleCancelAboutUs()}>Close</Button>
        </Row>
      </Modal>;


    let page = null;

    if(window.innerWidth >=768)
      page = <div id='mapHeader' style={{
        position: 'absolute',
        left: '0px',
        right: '0px',
        top: '0px'
      }}>
        <Row>
          <Col span={ 12 }>
            <img src={logosmall} alt='An accessibility map for your community' style={{maxHeight:'144px', marginLeft: '24px', marginBottom:'8px'}} />
          </Col>
          <Col span={12}  >
            <AboutUsIcon  style = {{float: 'right', margin:'20px 20px', fontSize:'36px'}} onClick={()=> this.handleShowAboutUs()} />
            <span style={{ position: 'absolute', bottom: 24, right: 24,width: '100%', textAlign: 'right', display: window.innerWidth < 768 ? 'none' : 'block' }}>
              <img src={markerIconBlack} alt='Accessibility report' style={{ marginRight: '8px', maxHeight:'48px' }} />
              <Label caption={window.innerWidth> 1180?'Accessibility Report Available':'Report Available'} labelType='H6' block={false} />
            </span>
          </Col>
        </Row>
      </div>;
   
    if (window.innerWidth < 768)
      page = <div id='mapHeader' style={{
        position: 'absolute',
        left: '0px',
        right: '0px',
        top: '0px'
      }}>
        <Row>
          <Col span={24}>
            <img src={logosmall} alt='Access for All' style={{ maxHeight: '140px' }} />
          </Col>
          <Col span={24} style={{ textAlign: 'center', padding: '0px 16px 16px 16px'}}>
          </Col>
        </Row>
      </div>;

    return (
      <React.Fragment>
        {page}
        {ModalWindow}
        {AboutUsModal}
      </React.Fragment>
    )
  }
}
export default MapHeader